@font-face {
  font-family: avantregular;
  /* src: url(./assets/fonts/Avantt-Regular.otf) format('opentype'); */
  src: url(./assets/fonts/Avantt-Regular.ttf) format('truetype');
  /* src: url(./assets/fonts/AvantRegular.ttf) format('truetype'); */
  
}

@font-face {
  font-family: gtpressura;
  src: url(./assets/fonts/GTPressuraMonoLightLight.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, avantregular, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  height: 0;
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover {
background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active {
background: rgba(0, 0, 0, 0.568);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
