.index {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	font-family: gtpressura;
	width: 100%;
	margin: 0;
	border-top: 2px solid black;
	margin-top: 90px;
	position: relative;
}

/* ARROWS */
button.arrow-down {
	display: inline block;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    z-index: 6;
    height: 60px;
    width: 60px;
    padding: 0;
	/* color: rgba(0, 0, 0, 0.507); */
	color: rgba(83, 83, 83, 0.507);
    margin: 20px;
	bottom: 0;
	position: fixed;
	animation: arrow-vertical 1s both infinite;
}

@keyframes arrow-vertical {
    0% { transform: translateY(0); }
    50% { transform: translateY(-0.3em); }
    100% { transform: translateY(0); }
} 

button.arrow-down:hover {
    color: rgba(194, 194, 194, 0.74);
}

.arrow-down svg {
    margin: 0;
    /* background-color: rgba(173, 173, 173, 0.527); */
    background-color: transparent;
	/* border: 2px solid black; */
    border-radius: 2px;
	height: 60px;
    width: 60px;
}

button.end {
	margin-left: 150px;
}

/* XL */
@media only screen and (max-width: 1280px) {
 
}

/* LG */
@media only screen and (max-width: 1024px) {
   
}

/* MD/SM */
@media only screen and (max-width: 768px) {
    .index {
		margin-top: 75px;
		justify-content: space-evenly;
	}

	.arrow-down {
		width: 100vw;
	}

	button.end {
		margin-left: 80vw;
	}
}
