.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.video-modal {
    z-index: 6;
    width: 100%;
    height: 100%;
    animation: scale linear 0.5s;
}

.play-button {
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* LOADING */
.vid-still {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.loading-still {
    z-index: 3;
}

.loading-animation {
    font-family: gtpressura;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    z-index: 4;
    margin: 0;
    top: 22vw;
    font-size: 25px;
    font-weight: bold;
    color: white;
    animation: wobble 2s both infinite;
}

.letter {
    display: inline;
}

.loading-o {
    width: 4.95rem;
    margin: 0;
}

@keyframes wobble {
    0% { transform: translateY(0); }
    50% { transform: translateY(-0.5em); }
    100% { transform: translateY(0); }
}

@keyframes scale {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* XL */
@media only screen and (max-width: 1280px) {
    .loading-animation {
        top: 24vw;
        font-size: 24px;
    }
}

/* MD/SM */
@media only screen and (max-width: 768px) {
    .loading-animation {
        top: 28vw;
        font-size: 20px;
    }
}

    
