.landing-text {
    font-family: gtpressura;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 26px;
    width: 100vw;
    height: 75px;
    position: absolute;
    bottom: 350px;
    padding: 0 50px;
    letter-spacing: 0.1rem;
    color: black;
    text-align: center;
    animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.preview {
    z-index: 5;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 56.25% 0 0 0;
}

/* MD/SM */
@media only screen and (max-width: 768px) {
    .landing-text {
        font-size: 18px;
        height: 80px;
        padding: 0 20px;
        margin-bottom: 20px; 
        bottom: 0;
        font-weight: 600;
    }

    .loading-animation {
        bottom: 350px;
    }

    .preview {
        transform: translateY(175px);
    }
}
