.cardContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	height: auto;
	border-bottom: 2px solid black;
	margin-top: 20px;
}

.cardCol {
	margin-bottom: 20px;
	width: 100%;
	height: 100%;
}

.project {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 6px 0 0 18px;
	margin-bottom: 4vw;
}

h2 {
	width: 100%;
	display: block;
	font-size: 2.75vw;
	padding: 0;
	margin: 0;
	color: black;
	letter-spacing: -0.05rem;
	line-height: 3.85rem;
}

h5 {
	width: 100%;
	display: block;
	font-size: 18px;
	color: rgb(116, 116, 116);
	letter-spacing: 0.05rem;
}

.info {
	margin-bottom: 20px;
}

.info p {
	bottom: 0;
	padding: 2px 0 5px 18px;
	font-size: 13px;
	line-height: 1em;
	color: rgb(116, 116, 116);
}

.third {
	padding-right: 50px;
}

figure {
	display: flex;
	position: relative;
	margin: 0;
	height: auto;
	background-color: transparent;
	width: 100%;
    height: 100%;
}

img {
	background-position: center;
	background-size: cover;
	cursor: pointer;
	margin-bottom: 20px;
	padding: 0;
}

figure.image img {
	cursor: pointer;
}

iframe {
	width: 100%;
    height: 500px;
}

/* XL */
@media only screen and (max-width: 1280px) {
 
}

/* LG */
@media only screen and (max-width: 1024px) {

}

/* MD/SM */
@media only screen and (max-width: 768px) {
	.cardContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: auto;
	}

	div.mobile-layout {
		display: flex;
		flex-wrap: nowrap;
		text-align: center;
		justify-content: space-between;
		width: 100%;
		margin: 0;
	}

	.project {
		width: 100%;
		padding: 0;
		margin: 0px 18px;
		margin-bottom: 40px;
	}
	/* .project {
		padding: 6px 0 0 18px;
		margin-bottom: 0px;
	} */

	.info {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 30px;
		margin: 0;
		padding: 0;
		line-height: normal;
	}

	.info p {
		padding: 2px 18px 5px 18px;
		margin: 0 5px;
	}

	.cardCol {
		margin-bottom: 0;
	}

	figure.image.column {
		margin-left: 15px;
		padding: 0;
		margin-bottom: 10px;
	}

	div.image, div.column {
		padding: 0;
	}
	
	iframe {
		width: 100%;
    	height: 300px;
	}
}
