.toolbar {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    margin: 0;
    animation: fadeIn ease 2s;
}

/* COOOL ANIMATION */
.coool {
    font-family: avantregular;
    font-size: 117.5px;
    width: 100%;
    margin: 0;
    line-height: 1.195em;
    letter-spacing: -0.285rem;
    color: black;
    animation: expand 3s linear -0.07s forwards;
    padding: 0 35px 0 30px;
}

.no-animation-coool {
    font-family: avantregular;
    font-size: 117.5px;
    width: 100%;
    margin: 0;
    line-height: 1.195em;
    letter-spacing: -0.285rem;
    color: black;
}

div {
    display: inline;
}

.load-text {
    display: flex;
    width: fit-content;
    position: relative;
    width: 100vw;
}

.loading-text {
    margin: 0;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.loading-text::-webkit-scrollbar {
    width: 0; 
    height: 0;
}

.loading-text::-webkit-scrollbar-thumb {
    background: transparent; 
}

.each-o {
    cursor: pointer;
}

/* RIGHT AND LEFT BUTTONS */
.buttons-container {
    display: flex;
    z-index: 6;
    position: absolute;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

button {
    border: none;
    border-radius: 2px;
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    z-index: 6;
    position: absolute;
    height: 140px;
    width: 70px;
    margin: 0;
    padding: 0;
}

.arrow {
    color: rgba(0, 0, 0, 0.507);
    margin: 0;
}

.arrow:hover {
    color: rgba(0, 0, 0, 0.74);
}

.arrow svg {
    margin: 0;
    background-color: transparent;
    border-radius: 2px;
    animation: arrow-horizontal 1s both infinite;
}

.right {
    transform: translateX(-1.35em);
}
.left {
    transform: translateX(0.6em);
}

/* ANIMATION KEYFRAMES */
@keyframes arrow {
    0% { transform: translateY(0); }
    50% { transform: translateY(-0.3em); }
    100% { transform: translateY(0); }
} 

@keyframes arrow-horizontal {
    0% { transform: translateX(0); }
    50% { transform: translateX(-0.2em); }
    100% { transform: translateX(0); }
} 

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes expand {
    from {width: 0;}
    to {width: 100%;}
}

hr {
    border-top: 2px solid black;
    margin: 0;
    width: 100%;
}

/* NAV LINKS */
.links {
    z-index: 6;
    position: absolute;
    top: 142px;
    font-family: gtpressura;
    font-weight: 500;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    animation: fadeIn ease 2s;
    justify-content: space-between;
}

.current-link {
    font-size: 30px;
    margin: 0;
    padding: 20px 30px;
    text-decoration: none;
    color: gray;
}

.current-link:hover {
    color: gray;
}

.toolbar-links,
p {
    font-size: 30px;
    margin: 0;
    padding: 20px 30px;
    text-decoration: none;
    color: black;
}

.toolbar-links:hover {
    color: gray;
}

.links-with-video a, .links-with-video p {
    color: white;
}

/* PREVIEW ARROW UP ANIMATION */
.up-arrow {
    animation: arrow 1s both infinite;
    padding: 0;
    margin-top: 20px;
}

.preview-container p {
    display: inline;
    z-index: 5;
}

.p-container {
    padding: 20px 15px;
    text-align: center;
}

.preview-container {
    display: flex;
    animation: fadeIn ease 1s;
}

/* XL */
@media only screen and (max-width: 1280px) {
 
}

/* LG */
@media only screen and (max-width: 1024px) {
   
}

/* MD/SM */
@media only screen and (max-width: 768px) {
    .coool,
    .no-animation-coool {
        font-size: 94px;
        padding: 0px 15px 0px 10px;
    }

    .links {
        top: 125px;
        font-weight: 600;
        padding: 0 20px;
        align-items: center;
    }

    .toolbar-links, .current-link, .links-with-video p {
        font-size: 24px;
        margin: 0;
        padding: 8px 0px;
        text-decoration: none;
    }

    .links-with-video a, p {
        color: black;
    }

    .p-container {
        font-size: 24px;
        padding: 8px 8px;
    }

    .links-with-video p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        width: 150px;
        height: 50px;
        color: black;
        margin: 0;
    }

    .up-arrow {
        margin-top: 8px;
    }

    button {
        height: 112px;
        width: 60px;
    }

    .right {
        transform: translateX(-1.1em);
    }
}
