.header {
    width: 60%;
    margin-bottom: 30px;
}

h2 {
    font-family: avantregular;
}

.form h3 {
    font-size: 47px;
    color: rgb(46, 46, 46);
    line-height: 2.75rem;
    font-family: avantregular;
}

.container {
   width: 100vw;
   height: auto;
}

.contact-page {
    margin: 80px 20px;
    width: 100%;
}

.button {
    border: solid 2px gray;
    border-radius: 0;
    font-size: 40px;
    height: 65px;
    width: 175px;
    padding-bottom: 25px;
    margin: 0 0 50px 0;
}

.form {
    margin-left: 55px;
} 

.input, .textarea {
    font-family: gtpressura;
    text-shadow: 0px 0px 0.5px;
    font-size: 40px;
    margin: 6px 0 6px 0;
    background-color: hsl(0, 0%, 96%);
    border: none;
    border-radius: 0;
}

.alert {
    display: block;
    margin: 0;
}

.notification {
    font-family: gtpressura;
}

.email {
    font-size: 18px;
    padding: 0;
}

figure.image img {
    margin: 0;
    cursor: default;
}

div.form.column {
    padding-left: 50px;
    margin: 0;
    width: 55vw;
}

.dev-credits {
    font-family: gtpressura;
    position: relative;
    display: flex;
    justify-content: flex-end;
    bottom: 25px;
    padding: 0;
    margin: 0;
}

.credit {
    padding: 0 30px 0 10px;
    font-size: 16px;
    text-align: center;
}

.credit a {
    color: seagreen;
}
.credit a:hover {
    color: lightseagreen;
}

/* XL */
@media only screen and (max-width: 1280px) {
    .header {
        width: 100%;
        text-align: center;
    }

    .form {
        margin: 0 30px 0 20px;
    }
}

/* LG */
@media only screen and (max-width: 1024px) {
    .header {
        width: 100%;
        text-align: center;
    }

    div.form.column {
        padding-left: 20px;
    }
}

/* MD/SM */
@media only screen and (max-width: 768px) {
    .header {
        width: 100%;
        text-align: center;
        padding: 0 15px;
    }
    
    .contact-page {
        top: 35px;
    }

    .form {
        margin: 0 10px 0 10px;
    }

    h3 {
        margin-top: 15px;
    }

    .image {
        transform: scale(0.90);
    }

    figure.image {
        margin: 0;
    }

    div.form.column {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        width: 100vw;
        padding: 0;
    }

    div.field, div.notification {
        width: 90vw;
    }

    .dev-credits {
        bottom: 10px;
    }

    .field.is-grouped {
        justify-content: center;
    }
}
